


















































































































import { Component, Vue } from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import { postExamination, putExamination } from '@/api/myExam/examinationAnswer.ts';
import myFooter from '@/components/Footer.vue';
import { getQinToken } from '@/api/courseWork/myHomework/answerSheet';
import axios from 'axios';

@Component({
    components: {
        myHeader,
        myFooter
    }
})
export default class ExaminationAnswer extends Vue {
  // textAnswer: any;
  data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      semester_id: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      crs_id: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      stu_exam: {},
      // eslint-disable-next-line @typescript-eslint/camelcase
      ques_id: 0
  };

  // 题目数据
  questionsData = [{
      // eslint-disable-next-line @typescript-eslint/camelcase
      options: [{ is_selected: true }],
      // eslint-disable-next-line @typescript-eslint/camelcase
      has_answer: true,
      // eslint-disable-next-line @typescript-eslint/camelcase
      boolean_answer: true,
      // eslint-disable-next-line @typescript-eslint/camelcase
      text_answer: ''
  }];

  // 课程名字
  crsName = '';
  // 节名字
  sectionName = '';
  // 总题目数
  totalSubject = 0;
  // 已做多少题
  finishSubject = 0;
  // 缓存定时器
  mobileSaveTimer = 0;
  // 浮动题目标题状态
  titleFixed = true;
  // 分
  branch = 0;
  // 秒
  second = '';
  // 倒计时
  timer = 0;
  // 选中描点
  numberIndex = 0;
  // 试卷id
  examId = 0;
  // 弹窗状态
  popupState = false;
  // 确定交卷
  determineSate = false;
  // 跳转
  jumpSate = false;
  // 5s倒计时
  count = 5;
  // 计时器
  countTimer = 0;
  // 加载状态
  loadingIndex = true;
  imgshowlist = [] as any [];
  selectedFile = '';

  mounted() {
      this.getData();
  }

  // 方法
  getData() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.data.crs_id = parseInt(this.$route.query.crsId as string);
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.data.ques_id = parseInt(this.$route.query.quesId as string);
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.data.stu_exam = JSON.parse(this.$route.query.stuExam as string);
      this.crsName = this.$route.query.crsName as string;
      // eslint-disable-next-line @typescript-eslint/camelcase
      const checksem = JSON.parse(localStorage.getItem('checkSemester') as string);
      if (checksem !== null) {
          this.data.semester_id = parseInt(checksem.id);
      } else {
          this.data.semester_id = parseInt(localStorage.getItem('semesterId') as string);
      }

      postExamination(this.data).then((res: any) => {
          this.loadingIndex = false;
          this.finishSubject = res.answer_num;
          this.questionsData = res.ques_list;
          this.totalSubject = res.total;
          this.examId = res.exam_id;
          const myDate = new Date();
          const newTime = (new Date(myDate)).getTime();
          this.timeFun(res.exam_end_time * 1000 - newTime);
          this.timer = setInterval(() => {
              if (this.second !== '00') {
                  const temp = parseInt(this.second) - 1;
                  this.second = temp.toString();
              }
              if (parseInt(this.second) < 10) {
                  if (this.second !== '00') {
                      this.second = '0' + this.second;
                  }
                  if (this.second === '00') {
                      // 清除定时器
                      if (this.branch === 0 && this.second === '00') {
                          // this.pushAnswer();
                          // clearInterval(self.timer);
                          return;
                      }
                      this.second = '59';
                      this.branch--;
                  }
              }
          }, 1000);
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 转换时间
  timeFun(time: any) {
      const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((time % (1000 * 60)) / 1000);
      this.branch = (hours * 60) + minutes;
      this.second = seconds.toString();
      if (this.second === '0') {
          this.second = '00';
      }
  }

  // 单选 多选
  changeChoice(index: number, subscript: number, type: string) {
      if (type === 'radio') {
          for (let i = 0; i < 4; i++) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.questionsData[index].options[i].is_selected = false;
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.questionsData[index].options[subscript].is_selected = true;
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.questionsData[index].has_answer = true;
      } else {
      // eslint-disable-next-line @typescript-eslint/camelcase
          if (this.questionsData[index].options[subscript].is_selected) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.questionsData[index].options[subscript].is_selected = false;
          } else {
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.questionsData[index].options[subscript].is_selected = true;
          }
          // 判断是否有做题
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.questionsData[index].has_answer = this.questionsData[index].options.some((item) => {
              return item.is_selected;
          });
      }
      this.countTopic();
  }

  // 判断题
  changeBoolean(index: number, answer: boolean) {
      // 节流
      if (answer === this.questionsData[index].boolean_answer) {
          return;
      }
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.questionsData[index].has_answer = true;
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.questionsData[index].boolean_answer = answer;
      this.countTopic();
  }

  // 其他题目
  mouseLeave(index: number) {
      if (this.questionsData[index].text_answer.trim() === '') {
      // eslint-disable-next-line @typescript-eslint/camelcase
          this.questionsData[index].has_answer = false;
      } else {
      // eslint-disable-next-line @typescript-eslint/camelcase
          this.questionsData[index].has_answer = true;
      }
      this.countTopic();
  }

  // 改变弹窗状态
  openPopup() {
      this.popupState = true;
      this.determineSate = true;
  }

  // 改变弹窗状态
  closePopup() {
      this.popupState = false;
      this.determineSate = false;
      this.jumpSate = false;
  }

  // 交卷
  submit() {
      const examinationData = {
      // eslint-disable-next-line @typescript-eslint/camelcase
          exam_id: this.examId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          semester_id: this.data.semester_id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          question_list: this.questionsData
      };
      putExamination(examinationData).then(() => {
          this.determineSate = false;
          this.jumpSate = true;
          this.countTimer = setInterval(() => {
              this.count--;
              if (this.count === 0) {
                  clearInterval(this.countTimer);
                  this.$router.push({
                      path: '/myExam'
                  });
              } else if (this.count <= 0) {
                  clearInterval(this.countTimer);
                  this.$router.push({
                      path: '/myExam'
                  });
              }
          }, 1000);
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 跳转到我的考试
  goMyExam() {
      clearInterval(this.countTimer);
      this.$router.push({
          path: '/myExam'
      });
  }

  // 计算题目
  countTopic() {
      let count = 0;
      this.questionsData.forEach((item) => {
          if (item.has_answer) {
              count++;
          }
      });
      this.finishSubject = count;
  }

  // 题目定位锚点
  goSubject(id: any, index: number) {
      this.numberIndex = index;
      id = '#subject' + id;
      document.querySelector(id).scrollIntoView(true);
  }

  // 题目定位状态改变
  changeState() {
      this.titleFixed = !this.titleFixed;
  }

  // 路由守卫
  beforeRouteLeave() {
      clearInterval(this.mobileSaveTimer);
  }

  handleFileUpload(event: any, index: number) {
      this.selectedFile = event.target.files[0];
      getQinToken({}).then((res: any) => {
          const data = new FormData();
          data.append('token', res.token);
          data.append('key', res.key);
          data.append('file', this.selectedFile);
          axios.post('https://up-z2.qiniup.com', data).then(response => {
              this.imgshowlist[index] = true;
              this.questionsData[index].text_answer = 'https://c.zikaoj.com/' + res.key;
              this.questionsData[index].has_answer = true;
              this.countTopic();
          }).catch(error => {
              // 请求失败，
              console.log(error);
          });
      }).catch((error: any) => {
          console.log(error);
      });
  }
}

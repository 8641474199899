import { post, put } from '@/utils/http.js';

/*
function:获取考试试卷数据
author:ysl
time:2021/03/24
*/

export function postExamination(data: any) {
    return post('/examination/mobile/management', data);
}

/*
function:交卷
author:ysl
time:2021/03/24
*/

export function putExamination(data: any) {
    return put('/examination/mobile/management', data);
}
